import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Body from 'components/common/body';
import { getFontCss, theme } from 'utils/theme';

type InputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface StyledInputProps {
  width?: string;
}

export type InputProps = Pick<
  InputElementProps,
  | 'className'
  | 'disabled'
  | 'id'
  | 'multiple'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onDragOver'
  | 'onDragLeave'
  | 'onDrop'
  | 'onFocus'
  | 'placeholder'
  | 'required'
  | 'type'
  | 'value'
  | 'accept'
> & { label: string; name: string; description?: string } & StyledInputProps;

const CustomSimpleInput = styled.input<StyledInputProps>`
  font-size: 1rem;
  border: none;
  background-color: inherit;
  ${(props) => (props.width ? `width ${props.width};` : '')}
  ${getFontCss({})}
padding: 10px 20px;
  display: flex;
  outline: none;
`;

export const SimpleTextInput = ({ label, ...props }: InputProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <span>
      <label htmlFor={props.id || props.name}>
        <Body>{label}</Body>
      </label>
      <CustomSimpleInput className="text-input" {...field} {...props} />
    </span>
  );
};

const CustomInput = styled.input<StyledInputProps>`
  border: 1px solid ${theme.colors.gray300};
  border-radius: 10px;
  width: ${(props) => props.width || '300px'};
  background-color: inherit;
  ${(props) => (props.width ? `width ${props.width};` : '')}
  ${getFontCss({})}
  padding: 10px 20px;
  display: flex;

  &:focus {
    outline: none;
    border-color: ${theme.colors.gray600};
  }
`;

const TextInputDiv = styled.div`
  margin: 20px 0;
`;

export const TextInput = ({ label, description, ...props }: InputProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, errors] = useField(props);
  return (
    <TextInputDiv>
      <label htmlFor={props.id || props.name}>
        <Body>{label}</Body>
      </label>
      <CustomInput className="text-input" {...field} {...props} />
      <Body size="xsmall">{description}</Body>
      <Body size="xsmall" color={theme.customColors.error}>
        {errors.touched && errors.error}
      </Body>
    </TextInputDiv>
  );
};
