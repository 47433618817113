import Head from 'next/head';
import React from 'react';
import Body from 'components/common/body';
import EnvLabel from 'components/common/environment/label';
import Heading from 'components/common/heading';
import Link from 'components/common/link';
import { SocialMediaIcons } from 'components/common/socialMedia';
import type { LayoutProps } from 'components/layouts/types';
import Logo from 'components/logo';
import { makePageTitle } from 'constants/strings';
import { HEADER_HEIGHT } from 'constants/style';
import { BLOG_URL, HOME_URL, PROJECTS_URL, RESUME_URL } from 'constants/urls';
import { theme } from 'utils/theme';
import { useStaticRouter, pathnameIncludes } from 'utils/useStaticRouter';
import { HeaderContentContainer, Footer, Header, Wrapper, Nav } from '../styles';

type PublicLayoutProps = LayoutProps & { children: React.ReactNode };

const PublicLayout: React.FC<PublicLayoutProps> = ({ children, title }) => {
  const router = useStaticRouter();
  const localPathnameIncludes = (pathname: string) => pathnameIncludes(router, pathname);
  const makeCopyrigthText = () => `Mihai Anton © | ${new Date().getFullYear()}`;
  const isMainPage = localPathnameIncludes(HOME_URL);
  const navItemHeadingLevel = 400;

  return (
    <>
      <Head>
        <title>{makePageTitle(title)}</title>
      </Head>
      <Wrapper>
        <HeaderContentContainer
          position={isMainPage ? 'relative' : undefined} // on / we want the header to scroll up with the page
          paddingTop={isMainPage ? undefined : HEADER_HEIGHT}
        >
          <Header
            showBackgroundEffects={!isMainPage}
            position={isMainPage ? 'absolute' : 'fixed'} // on / we want the header to be in the image
          >
            {/* Keep the link so flex pushes the nav to the right even though the logo is absent */}
            <Link href={HOME_URL}>{!isMainPage && <Logo />}</Link>

            <Nav>
              <EnvLabel />
              <Link href={PROJECTS_URL}>
                <Heading selected={localPathnameIncludes(PROJECTS_URL)} level={navItemHeadingLevel}>
                  Projects
                </Heading>
              </Link>
              <Link href={BLOG_URL}>
                <Heading selected={localPathnameIncludes(BLOG_URL)} level={navItemHeadingLevel}>
                  Blog
                </Heading>
              </Link>
              <Link href={RESUME_URL}>
                <Heading selected={localPathnameIncludes(RESUME_URL)} level={navItemHeadingLevel}>
                  Resume
                </Heading>
              </Link>
            </Nav>
          </Header>
          {children}
        </HeaderContentContainer>
        <Footer>
          <Body>{makeCopyrigthText()}</Body>
          <SocialMediaIcons color={theme.colors.black} baseHeight={24} />
        </Footer>
      </Wrapper>
    </>
  );
};

export default PublicLayout;
