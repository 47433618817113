import styled from 'styled-components';
import { theme } from 'utils/theme';

interface SimpleButtonProps {
  backgroundColor?: string;
}

const SimpleButton = styled.button<SimpleButtonProps>`
  margin: 20px 0px;
  padding: 10px 20px;
  border: 1px solid ${theme.colors.gray300};
  background-color: ${(props) => props.backgroundColor || theme.colors.white};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.5);
  }

  &:disabled {
    opacity: 0.4;

    &:hover {
      box-shadow: none;
    }
  }
`;

export default SimpleButton;
