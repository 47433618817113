import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import Heading from 'components/common/heading';
import FadeIn from 'components/common/transitions/FadeIn';
import { TextInput } from 'components/form/input';
import { TextArea } from 'components/form/textArea';
import { ADMIN_EMAIL_ADDRESS } from 'constants/env';
import {
  AddContactSubmissionMutation,
  AddContactSubmissionMutationVariables,
} from 'graphql/generated';
import { theme } from 'utils/theme';
import { ADD_CONTACT_SUBMISSION } from './query';

const ContactMeDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 10px;
`;

const Form = styled.form`
  margin: 20px 0;
  width: 100%;
`;

interface ContactMeProps {
  title?: string;
  messageQuestion?: string;
}

const ContactMe = ({ title, messageQuestion }: ContactMeProps) => {
  const [successMessage, setSuccessMessage] = useState<null | string>(null);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [successOrErrorInView, setSuccessOrErrorInView] = useState(false);

  const [addContactSubmission, { loading: addContactSubmissionLoading }] = useMutation<
    AddContactSubmissionMutation,
    AddContactSubmissionMutationVariables
  >(ADD_CONTACT_SUBMISSION);

  return (
    <ContactMeDiv>
      <Heading level={200}>{title || 'Contact me!'}</Heading>
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.name) {
            errors = { ...errors, name: 'Please add your name' };
          }
          if (!values.email) {
            errors = { ...errors, email: 'Please add your email' };
          }
          if (!values.message) {
            errors = { ...errors, message: 'Please tell us how we can help you' };
          }
          return errors;
        }}
        onSubmit={async ({ name, email, message }, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          const { data: addContactSubmissionResult } = await addContactSubmission({
            variables: { name, email, message },
          });

          setSubmitting(false);
          resetForm();

          if (addContactSubmissionResult?.addContactSubmission.ok) {
            setSuccessMessage(`Thank you. I'll be in touch soon!`);
          } else {
            setErrorMessage(
              `An error occured. Please try again later or email ${ADMIN_EMAIL_ADDRESS}`,
            );
          }

          setSuccessOrErrorInView(true);
          setTimeout(() => {
            setSuccessMessage(null);
            setErrorMessage(null);
          }, 4000);
        }}
      >
        {({ isSubmitting, handleSubmit, isValid, submitForm }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextInput
              id="name"
              label="Your name"
              name="name"
              type="text"
              placeholder="Product Builder"
            />
            <TextInput
              id="email"
              label="Your email"
              name="email"
              type="email"
              placeholder="product@builder.com"
              description={`Won't be shared with anybody else 🙂`}
            />
            <TextArea
              id="message"
              label={messageQuestion || 'How can I help you?'}
              name="message"
              placeholder="Let's build awesome stuff together!"
            />
            <SimpleButton
              onClick={submitForm}
              disabled={isSubmitting || !isValid || addContactSubmissionLoading}
              backgroundColor={theme.customColors.success}
            >
              <Body color={theme.colors.black}>
                {addContactSubmissionLoading ? 'Submitting..' : 'Submit'}
              </Body>
            </SimpleButton>
            <FadeIn inView={successOrErrorInView}>
              <>
                {successMessage && <Body>{successMessage}</Body>}
                {errorMessage && <Body color={theme.customColors.error}>{errorMessage}</Body>}
              </>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </ContactMeDiv>
  );
};

export default ContactMe;
