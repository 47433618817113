import Image, { ImageProps } from 'next/image';
import React from 'react';
import { BlogPostContentType } from 'constants/blog';

type BlogImageProps = ImageProps & { type: BlogPostContentType };

const BlogImage = ({ type, ...rest }: BlogImageProps) => {
  switch (type) {
    case BlogPostContentType.NORMAL_IMAGE:
      return (
        <Image
          {...rest}
          width="0"
          height="0"
          sizes="100vw"
          alt={rest.alt || 'Image'}
          style={{ height: 'auto', width: '100%' }}
          priority
          loading="eager"
        />
      );
    case BlogPostContentType.MEDIUM_IMAGE:
      return (
        <Image
          {...rest}
          width="0"
          height="0"
          sizes="100vw"
          alt={rest.alt || 'Image'}
          style={{ height: 'auto', width: 'calc((100vw + 100%) / 2)' }}
          priority
          loading="eager"
        />
      );
    case BlogPostContentType.FULL_IMAGE:
      return (
        <Image
          {...rest}
          width="0"
          height="0"
          sizes="100vw"
          alt={rest.alt || 'Image'}
          style={{
            height: 'auto',
            width: '100vw',
          }}
          priority
          loading="eager"
        />
      );
    default:
      return null;
  }
};

export default BlogImage;
