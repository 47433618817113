import React from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import styled from 'styled-components';
import { getFontCss } from 'utils/theme';

const CodeBlockDiv = styled.div`
  width: 100%;
  ${getFontCss({})}; // so the code component inherits the font
  margin: 5px 0;
`;

export interface BlogPostCodeBlockProps {
  content: string[];
}

// more themes at https://github.com/rajinwonderland/react-code-blocks/blob/master/THEMES.md
// if the code has `, they need to be escaped; for $ signs, use \u0000 after, so there's a null separator and is does not interpolate
const BlogPostCodeBlock = ({ content }: BlogPostCodeBlockProps) => {
  const [language, code] = content;

  return (
    <CodeBlockDiv>
      <CodeBlock
        text={code}
        language={language}
        showLineNumbers
        startingLineNumber={1}
        theme={dracula}
      />
    </CodeBlockDiv>
  );
};

export default BlogPostCodeBlock;
