export enum BlogPostContentType {
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  POST_INFO = 'POST_INFO',
  TEXT = 'TEXT',
  TEXT_IT = 'TEXT_IT',
  NUMBERED_LIST = 'NUMBERED_LIST',
  LIST = 'LIST',
  FULL_IMAGE = 'FULL_IMAGE',
  MEDIUM_IMAGE = 'MEDIUM_IMAGE',
  NORMAL_IMAGE = 'NORMAL_IMAGE',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  SECTION_BREAK = 'SECTION_BREAK',
  CODE_BLOCK = 'CODE_BLOCK',
  CONTACT_ME_FORM = 'CONTACT_ME_FORM',
  TEXT_LEFT_BORDER = 'TEXT_LEFT_BORDER',
}
